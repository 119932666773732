import React, {useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import { graphql, Link } from 'gatsby';
import Seo from '../components/seo/seo';
import ArrowSvg from '../assets/um-arrow-pagination.svg';
import PhoneSvg from '../assets/um-phone.svg';
import MailSvg from '../assets/um-mail.svg';
import ContactSvg from '../assets/um-contact.svg';
import CompanySvg from '../assets/um-company.svg';
import { useForm, useFormState } from 'react-hook-form';
import Button from '../components/button/button';
import InputMask from 'react-input-mask';
import '../styles/contact-page.sass';

const BlogList: React.FC = ({ data }: any) => {
  const wpPage = data.wpPage;

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  const [phone, setPhone] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const API_PATH = '/api/index.php';

  const sendEmail = (data: any) => {
    data.phone = document.getElementById('phone')?.value;

    if(isPhoneInvalid || !data.phone) return;

    fetch(API_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code !== 200) setIsError(true);
        if (res.code === 200) setIsEmailSent(true);
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  const validatePhone = () => {
    let trimmedPhone = String(document.getElementById('phone')?.value).split(' ').join('');
    setIsPhoneInvalid(!trimmedPhone || trimmedPhone.length < 9);
  }

  const handlePhoneKeyup = () => {
    setPhone(document.getElementById('phone')?.value);
    validatePhone();
  }

  useEffect(() => {
    if(Object.keys(errors).length === 0) return;

    validatePhone();
  }, [errors]);

  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.focuskw}
        pathname="/kontakt/"
      />
      <div className="bg-blueLight">
        <div className="container mx-auto max-w-6xl px-4 py-6 lg:py-20">
          <h1 className="text-center text-2xl font-semibold lg:text-4xl">
            Kontakt
          </h1>
          <div className="mt-5 mb-7 w-full text-center lg:mb-14">
            <Link to="/" className="hover:text-primary">
              Strona główna
            </Link>
            <img src={ArrowSvg} className="inline-block pl-2" />
            <span className="pl-2 text-primary">Kontakt</span>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="">
              <h2 className="font-secondary text-2xl">Skontaktuj się:</h2>
              <div className="mt-5 flex items-center">
                <img src={PhoneSvg} alt="" className="h-6 w-6" />
                <a
                  className="appearance-none pl-3 font-secondary transition-colors hover:text-primary"
                  href="tel:+48538234827"
                >
                  +48 538 234 827
                </a>
              </div>
              <div className="mt-3 flex items-center">
                <img src={MailSvg} alt="" className="h-6 w-6" />
                <a
                  className="appearance-none pl-3 font-secondary transition-colors hover:text-primary"
                  href="mailto:kontakt@ubezpieczamymieszkanie.pl"
                >
                  kontakt@ubezpieczamymieszkanie.pl
                </a>
              </div>
              <h2 className="mt-5 font-secondary text-2xl lg:mt-10">
                Pozostajemy do Twojej dyspozycji
              </h2>
              <div className="mt-5 flex items-center">
                <img src={ContactSvg} alt="" className="h-6 w-6" />
                <p className="pl-3 font-secondary ">
                  Od poniedziałku do piątku od 08:00 do 18:00
                </p>
              </div>
              <h2 className="mt-5 font-secondary text-2xl lg:mt-10">
                Wolisz tradycyjną pocztę listową?
              </h2>
              <p className="my-3 font-secondary font-medium">
                Napisz do nas na adres biura:
              </p>
              <div className="mt-5 flex items-start">
                <img src={CompanySvg} alt="" className="h-6 w-6" />
                <div className=" pl-3 font-secondary ">
                  LeadGuru Sp. z o.o.
                  <br />
                  ul. Sycowska 44
                  <br />
                  51-319 Wrocław
                </div>
              </div>
            </div>

            {isError && <div>Niestety wystąpił błąd, spróbuj ponownie.</div>}
            {isEmailSent && (
              <div className="flex flex-col items-center text-primary">
                Dziękujemy za wiadomość{' '}
                <span className="mt-3 block">
                  Wkrótce skontaktujemy się z Tobą.
                </span>
              </div>
            )}
            {isEmailSent === false && isError === false && (
              <div className="mt-10 lg:mt-0">
                <form
                  onSubmit={handleSubmit(sendEmail)}
                  className="flex flex-col"
                >
                  <label htmlFor="name" className="mb-2 font-secondary">
                    Imie i nazwisko
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Wpisz imię i nazwisko"
                    {...register('name', {
                      required: true,
                      maxLength: 100,
                    })}
                    className={`rounded-md border-[1px] border-gray-200 py-3 px-5 placeholder:text-gray-300 ${
                      errors.name ? 'border-red-400' : ''
                    }`}
                  />
                  {errors.name?.type === 'required' && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      Imie i nazwisko jest wymagane
                    </span>
                  )}

                  <label htmlFor="phone" className="mb-2 mt-4 font-secondary">
                    Telefon
                  </label>
                  
                  <InputMask mask="999 999 999" maskPlaceholder={null} onChange={handlePhoneKeyup}>
                    <input
                      id="phone"
                      inputMode="numeric"
                      pattern="[0-9 ]*"
                      placeholder="Podaj swój numer telefonu"
                      className={`rounded-md border-[1px] border-gray-200 py-3 px-5 placeholder:text-gray-300 ${
                        isPhoneInvalid ? 'border-red-400' : ''
                      }`}
                    />
                  </InputMask>

                  {isPhoneInvalid && !phone && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      Numer telefonu jest wymagany.
                    </span>
                  )}

                  {isPhoneInvalid && phone && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      Numer telefonu jest niepoprawny.
                    </span>
                  )}      

                  <label htmlFor="email" className="mb-2 mt-4 font-secondary">
                    E-mail
                  </label>
                  <input
                    id="email"
                    type="email"
                    inputMode="email"
                    placeholder="Podaj swój adres e-mail"
                    {...register('email', {
                      required: true,
                      maxLength: 50,
                      pattern: /^\S+@\S+$/i,
                    })}
                    className={`rounded-md border-[1px] border-gray-200 py-3 px-5 placeholder:text-gray-300 ${
                      errors.email ? 'border-red-400' : ''
                    }`}
                  />
                  {errors.email?.type === 'required' && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      E-mail jest wymagany
                    </span>
                  )}
                  {errors.email?.type === 'pattern' && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      Niepoprawny adres e-mail
                    </span>
                  )}
                  {errors.email?.type === 'maxLength' && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      E-mail jest za długi
                    </span>
                  )}

                  <label htmlFor="message" className="mb-2 mt-4 font-secondary">
                    Treść wiadomości
                  </label>
                  <textarea
                    id="message"
                    {...register('message', { required: true })}
                    className={`rounded-md border-[1px] border-gray-200 py-3 px-5 placeholder:text-gray-300 ${
                      errors.message ? 'border-red-400' : ''
                    }`}
                    rows={5}
                  />
                  {errors.message?.type === 'required' && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      Wiadomość jest wymagana
                    </span>
                  )}
                  <div className="mt-4 flex items-start">
                    <input
                      type="checkbox"
                      id="terms"
                      placeholder="Oświadczenia"
                      {...register('terms', { required: true })}
                      className={` mt-1 scale-125 rounded-md border-[1px] border-gray-200 accent-primary placeholder:text-gray-300 ${
                        errors.terms ? 'border-red-400' : ''
                      }`}
                    />
                    <label
                      htmlFor="terms"
                      className="block pl-3 font-secondary text-sm"
                    >
                      Oświadczam, że zapoznałem się z{' '}
                      <Link
                        to="/polityka-prywatnosci/"
                        className="text-primary"
                      >
                        Polityką Prywatności
                      </Link>{' '}
                      i wyrażam zgodę na przekazanie danych do
                      UbezpieczamyMieszkanie.pl
                    </label>
                  </div>
                  {errors.terms?.type === 'required' && (
                    <span className="mt-1 animate-fade text-xs text-red-400">
                      Zgody są wymagane
                    </span>
                  )}

                  <Button
                    htmlType="submit"
                    visualType="primary"
                    className="mt-5 w-fit"
                  >
                    Wyślij wiadomość
                  </Button>
                </form>
              </div>
            )}
          </div>
        </div>
        <div className="container mx-auto max-w-6xl px-4 py-6 lg:py-10">
          <h1 className="text-center text-2xl font-semibold lg:text-4xl">
            Odwiedź nas w biurze
          </h1>
          <div className="mt-10">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2502.9188269144065!2d17.104476316131812!3d51.146847979577714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc32ea0828ad9%3A0xe3f524a702549675!2sLeadGuru%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1648746769263!5m2!1spl!2spl"
              width="600"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query KontaktPage {
    wpPage(slug: { eq: "kontakt" }) {
      seo {
        title
        focuskw
        metaDesc
      }
    }
  }
`;
